<template>
  <default-layout>
    <div class="container about--us-container">
      <banner :banner-text="bannerText" />
      <div class="row">
        <div class="col-12 col-md-6 left--col">
          <img
            src="https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/about-us/rentfix-full-team.jpg"
          />
        </div>
        <div class="col-12 col-md-6 right--col">
          <div class="title--text">
            {{ $t('about.header1') }}
          </div>
          <div class="content--text">
            {{ $t('about.message') }}
          </div>
          <router-link
            to="/sewa/search/semua/?property_category=7"
            class="btn btn-primary--outline"
          >
            {{ $t('about.RButton') }}
          </router-link>
          <router-link
            to="/sewa/search/semua/?property_category=8"
            class="btn btn-primary--outline"
          >
            {{ $t('about.CButton') }}
          </router-link>
        </div>
      </div>
      <div class="slider">
        <div class="title--text">Liputan Media</div>
        <slick-coverage />
      </div>
    </div>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/utils/page-banner';
import SlickCoverage from '@/components/about-us/slick-coverage';

export default {
  name: 'about-us',
  components: {
    DefaultLayout,
    Banner,
    SlickCoverage,
  },
  data: () => ({
    bannerText: 'about.header2',
  }),
  async fetch({ store, route }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
  },
};
</script>
