<template>
  <div>
    <v-slide-group v-if="coverages && coverages.length > 0" class="carousel--wrapper">
      <v-slide-item
        v-for="(coverage, index) in coverages"
        :key="`coverage-${index}`"
        class="carousel--slide"
      >
        <a :href="coverage.url" class="carousel--link">
          <img :src="coverage.img" :alt="coverage.name" class="carousel--image img-responsive" />
        </a>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  props: {
    listingPartners: {
      default: [],
      require: true,
    },
    propertyPartners: {
      default: [],
      require: true,
    },
    partnerType: {
      default: null,
      require: true,
    },
  },
  data: () => ({
    coverages: [
      {
        url: 'https://properti.kompas.com/read/2019/05/15/163000321/mengklaim-beda-dari-situs-lain-rentfix-fokus-pada-sewa-properti',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/kompas.jpg',
        name: 'Kompas.com',
      },
      {
        url: 'https://ekonomi.bisnis.com/read/20190520/47/924933/rentfix.com-tawarkan-bayar-sewa-kredit-properti-secara-online',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/bisniscom.jpg',
        name: 'Bisnis.com',
      },
      {
        url: '',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/kontan.png',
        name: 'Kontan.co.id',
      },
      {
        url: 'https://mediaindonesia.com/read/detail/284684-harga-rumah-perlu-terjangkau-untuk-milenial',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/mediaindonesia.jpg',
        name: 'Media Indonesia',
      },
      {
        url: 'https://www.propertynbank.com/optimalkan-pemanfaatan-aset-properti-negara-lman-tunjuk-rentfix-com/',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/propertynbank.jpg',
        name: 'PropertynBank',
      },
      {
        url: 'https://housingestate.id/read/2019/04/30/sewa-menyewa-properti-tinggal-klik-dari-mana-saja-kapan-saja/',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/housingestate.jpg',
        name: 'HousingEstate',
      },
      {
        url: 'https://marketing.co.id/rentfix-com-luncurkan-aplikasi-mobile-2-0-di-ios-dan-android/',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/marketing.jpg',
        name: 'Marketing.co.id',
      },
      {
        url: 'https://pingpoint.co.id/berita/rentfix-luncurkan-aplikasi-mobile-teranyar/',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/pingpoin.jpg',
        name: 'PingPoint',
      },
      {
        url: '',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/mediago.jpg',
        name: 'MediaGo',
      },
      {
        url: 'https://www.radarjakarta.com/berita-11275-%E2%80%9Crentfix-properti-online-fair-tempat-kaum-milenial-cari-properti-idaman.html',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/radarjakarta.png',
        name: 'RadarJakarta',
      },
      {
        url: 'https://serbapromosi.co/28411-rentfix-mobile-22-permudah-sewa-menyewa-properti',
        img: 'https://rentfixs3.s3-ap-southeast-1.amazonaws.com/public/static/img/coverages/serbapromosi.jpg',
        name: 'SerbaPromosi',
      },
    ],
  }),
  methods: {},
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';
.carousel--slide {
  @media #{$phones} {
    margin: 4px 8px;
    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }
  }
  .carousel--image {
    width: 180px;
    height: 180px;
    background-color: $color-white;
    border-radius: 24px;
    box-shadow: $box-shadow;
    object-fit: contain;
    padding: 16px;
    @media #{$phones} {
      width: 140px;
      height: 140px;
    }
  }
}
</style>
